import React from 'react';
import {
  withExperiments,
  translate,
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import { Button } from 'wix-ui-tpa/Button';
import styles from './Widget.st.css';
import TitleAndTagline from './TitleAndTagline';

interface ControllerProps {
  greetingsText: string;
  contentWidth: number;
}

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

export default translate()(
  withExperiments<WidgetProps>(
    ({ t, experiments, greetingsText, contentWidth, ...rest }) => {
      return (
        <div
          {...styles('root', {}, rest)}
          data-hook="booking-service-page-wrapper"
        >
          {/* <div className={styles.headerTemplate}>*/}
          {/*  <h2 data-hook="app-title">*/}
          {/*    {t('app.widget.welcome')} {greetingsText}!*/}
          {/*  </h2>*/}
          {/* </div>*/}
          {/*/ !* {This is a dummy experiment. To generate a new experiment,*/}
          {/*  check this guide: https://github.com/wix-private/fed-handbook/blob/master/EXPERIMENTS.md#create-experiment} *!/*/}
          {/* {experiments.enabled('specs.test.ShouldHideButton') ? null : (*/}
          {/*  <Button className={styles.mainButton}>Click me</Button>*/}
          {/* )}*/}
          <div
            data-hook="booking-service-page-header"
            className={styles.header}
          >
            Hello I'm The Header
          </div>
          <div className={styles.dynamicWrapper}>
            <div
              className={styles.dynamicItem}
              style={{ flexBasis: `${contentWidth}%` }}
            >
              <TitleAndTagline />
            </div>
            <div
              className={styles.dynamicItem}
              style={{ flexBasis: `${100 - contentWidth}%` }}
            >
              Hello I'm The Sidebar
            </div>
          </div>
        </div>
      );
    },
  ),
);
